// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "custom";

.sidebar-label {
    text-align: center;
    font-size: 44px;
    color: antiquewhite;
}
.table th, .table td {
    vertical-align: middle !important;
}